<template>
  <div class="pl-10 mt-n8">
    <div>
      <v-btn
        @click="callApi()"
        color="primary"
      >Call</v-btn>
    </div>

    <div class="mt-5">
      <pre>{{ JSON.stringify(result, null, 2) }}</pre>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      result: {},
    };
  },
  methods: {
    callApi() {
      let url =
        "https://lc-collector.azurewebsites.net/api/collector/case/685678E3-0FAC-49DF-8C02-42B199F18CE4/calllog/AAE122C3-DFBB-4C25-9080-02707F4CDFB9";

      this.result = "";
      this.$http
        .get(url, {
          disabledBaseUrl: true,
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJlZTA3YmQ0OS1lMzNlLTQ3OTEtYjc2My05YjkxN2M1OTk1Y2MiLCJuYW1lIjoic3lzYWRtaW4iLCJjZXJ0c2VyaWFsbnVtYmVyIjoiMXwiLCJyb2xlIjoiQURNSU4iLCJuYmYiOjE2OTM5NzkwNDEsImV4cCI6MTY5NjU3MTA0MSwiaWF0IjoxNjkzOTc5MDQxfQ.97fZb2_Um6czhb9wJVhIbfHIPYWI2FDifiqAEFnOX_4",
          },
        })
        .then((res) => {
          this.result = res;
        });
    },
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
};
</script>

<style>
</style>